export default [
  {
    path: '/admin/proxy-exclude',
    name: 'global-proxy-exclude-lists',
    component: () => import('@/views/globalProxyExcludeLists/GlobalProxyExcludeLists.vue'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'Global Proxy Exclude Lists',
      breadcrumb: [
        {
          text: 'Global Proxy Exclude Lists',
          active: true,
        },
      ],
    },
  },
]
