export default [
  {
    path: "/admin/purple-scribe/job-types",
    name: "admin-purple-scribe-job-types",
    component: () =>
      import("@/views/adminPurpleScribe/AdminPurpleScribeJobTypes.vue"),
    meta: {
      resource: "All",
      action: "Manage",
      pageTitle: "Session Types",
      breadcrumb: [
        {
          text: "Session Types",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin/purple-scribe/job-types/:id",
    name: "admin-purple-scribe-job-type-view",
    component: () =>
      import("@/views/adminPurpleScribe/ViewAdminPurpleScribeJobType.vue"),
    meta: {
      resource: "All",
      action: "Manage",
      pageTitle: "Session Types",
      breadcrumb: [
        {
          text: "Session Types",
          to: { name: "admin-purple-scribe-job-types" },
        },
        {
          text: "Session Type Details",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin/purple-scribe/summary-types",
    name: "admin-purple-scribe-summary-types",
    component: () =>
      import("@/views/adminPurpleScribe/AdminPurpleScribeSummaryTypes.vue"),
    meta: {
      resource: "All",
      action: "Manage",
      pageTitle: "Summary Types",
      breadcrumb: [
        {
          text: "Summary Types",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin/purple-scribe/summary-types/:id",
    name: "admin-purple-scribe-summary-type-view",
    component: () =>
      import("@/views/adminPurpleScribe/ViewAdminPurpleScribeSummaryType.vue"),
    meta: {
      resource: "All",
      action: "Manage",
      pageTitle: "Summary Types",
      breadcrumb: [
        {
          text: "Summary Types",
          to: { name: "admin-purple-scribe-summary-types" },
        },
        {
          text: "Summary Type Details",
          active: true,
        },
      ],
    },
  },
];
